<template>
  <div class="wallet-account">
    <div class="container-fluid mt--5">
      <AccountManage/>
    </div>

  </div>
</template>

<script>

import AccountManage from './components/AccountManage.vue'
export default {
  components:{
    AccountManage
  },
  
};
</script>

<style lang="scss">

</style>